import service from '@/utils/request';
export var uploadImage = function uploadImage(data) {
  return service({
    url: "/file/uploadFile",
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: data
  });
};